import React from "react";
// import * as FaIcons from "react-icons/fa";
// import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";

import "../css/App.css";
// import {IoIosBook} from "react-icons/io";

function Menu() {
  const Sidebar = [
    // {
    //   title: "Home",
    //   path: "/",
    //   icon: <AiIcons.AiFillHome />,
    //   cName: "nav-text",
    // },
    // {
    //   title: "Client",
    //   path: "/client",
    //   icon: <IoIcons.IoMdPeople />,
    //   cName: "nav-text",
    // },
    // {
    //   title: "PKS",
    //   path: "/pks",
    //   icon: <IoIcons.IoIosPaper />,
    //   cName: "nav-text",
    // },
    // {
    //   title: "Parameter",
    //   path: "/parameter",
    //   icon: <IoIcons.IoIosApps />,
    //   cName: "nav-text",
    // },
    // {
    //   title: "Invoice",
    //   path: "/invoice",
    //   icon: <IoIcons.IoIosBook />,
    //   cName: "nav-text",
    // },
    {
      title: "Tax Calculation",
      path: "/tax",
      icon: <IoIcons.IoIosBook />,
      cName: "nav-text",
    }
  ];

  return (
    <>
      <IconContext.Provider value={{ color: "undefined" }}>
        <nav className="nav-menu active">
          <ul className="nav-menu-items">
            {Sidebar.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Menu;