import React from "react";
import { createRoot } from "react-dom/client";

import {
  createBrowserRouter,
  RouterProvider,
  // Route,
  // Link,
  Outlet,
  // createRoutesFromElements,
} from "react-router-dom";

import Header from "./components/Header";
import Menu from "./components/Menu";

import Home from "./routes/Home";
// import Client from "./routes/Client";
// import PKS from "./routes/PKS";
// import Parameter from "./routes/Parameter";


import "./css/App.css";
// import Invoice from "./routes/Invoice";

import Tax from "./routes/Tax";

const AppLayout = () => (
  <>
    <div className="container">
      <div className="LHeader"><Header /></div>
      <div className="LMenu"><Menu /></div>
      <div className="LContent"><span className="w100"><Outlet /></span></div>
      <div className="clear"></div>
    </div>
  </>
);

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <Tax />,
      },
      // {
      //   path: "client",
      //   element: <Client />,
      // },
      // {
      //   path: "pks",
      //   element: <PKS />,
      // },
      // {
      //   path: "parameter",
      //   element: <Parameter />,
      // },
      // {
      //   path: "invoice",
      //   element: <Invoice />,
      // },
      {
        path: "tax",
        element: <Tax />,
      }
    ],
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
