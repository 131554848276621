import React from "react";

import "../css/App.css";

function Header() {
  return (
    <>
      <div>
        <img src={process.env.PUBLIC_URL + '/images/permata-white.png'} height="50" alt="" /> 
      </div>
    </>
  );
}

export default Header;
