import React, {Fragment} from 'react'
import {PulseLoader} from 'react-spinners';

export default function Loading() {
    return <Fragment>
        <div className="loading">
            <div>
                <PulseLoader color={'#B00020'} loading={true} />
            </div>
            <div>
                Loading data...
            </div>
        </div>
    </Fragment>
}