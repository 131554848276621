import {
    Autocomplete,
    Button,
    Container,
    TextField
} from "@mui/material";

import Grid2 from "@mui/material/Unstable_Grid2";
import React, {useEffect, useState} from "react";
import * as IoIcons from "react-icons/io";
import Loading from "../components/Loading";
import axios from "axios";

const config = require('../config/config.json')[0];

// const style = {
//     'groupHeader': {
//         position: 'sticky',
//         top: '-8px',
//         padding: '4px 10px',
//         color: '#ffffff',
//         backgroundColor: '#575e87'
//     }
// }

function Tax() {
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    // const [token, setToken] = useState('')
    const [state, setState] = useState({client: '', pks: '', userName: '', password: ''})
    const [client, setClient] = useState([])
    const [pks, setPKS] = useState([])
    // const [componentSalary, setComponentSalary] = useState([
    //     {
    //         icom_order: 0,
    //         icom_description: '',
    //         icom_name: [],
    //         icom_is_formula: '',
    //         icom_flag_show: '',
    //         icom_flag_line: ''
    //     }])

    const handleChange = (e) => {
        setState({...state, [e.target.id]: e.target.value})
    }

    const handleChangeClients =  async (id, value) => {
        setState({...state, [id]: value})
        try {
            const response = await axios.post(config.base + "/api/pks/getList", {"filter": parseInt(value.split(' | '))})
            const data = await response.data
            setPKS(data.data)
        } catch (error) {
            console.error('Error fetching pks:', error);
        }
    }

    const fetchClients = async () => {
        try {
            setLoading(true);
            const response = await axios.post(config.base + "/api/client/getAll", {"page": page, "filter": ""})
            const data = await response.data.data
            setClient([...client, ...data])
            setPage(page + 1)
        } catch (error) {
            console.error('Error fetching clients:', error);
        } finally {
            setLoading(false);
        }
    };

    
    const downloadData = async () => {
        try {
            window.open(config.base + "/api/tax/getByClient/" + parseInt(state['client'].substr(0,4)) + '/' + (state['pks'] === '' ? "All" : parseInt(state['pks'].substr(0,4)))+ '/TAX FINAL- ' + state['client'].substr(0,4) + ' - ' + (state['pks'] === '' ? "All" : state['pks'].substr(0,4)))
        } catch (error) {
            console.error('Error fetching Tax : ', error);
        }
    }

    const doLogin = async () => {
        try {
            const response = await axios.post(config.base + "/api/user/doLogin", {"n": state['userName'], "p":state['password']})
            if(response.data.status === 'success'){
                const nm = response.data.data[0].username;
                sessionStorage.setItem('token', nm);
                alert('Welcome, ' + nm);
            }else{
                alert('Access Denied')
            }

            window.location.reload();
        } catch (error) {
            console.error('Error login : ', error);
        }
    }

    useEffect(() => {
        fetchClients();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
    }, [state, client])

    if (loading) return Loading();
    if(sessionStorage.getItem('token') === '' || sessionStorage.getItem('token') === null){
        return (
            <Container>
            <br/>
            <br/>
            <h2>Tax Calculation</h2>
            <br/>
            <Grid2 container spacing={2}>
                <Grid2 md={12} sm={12}>
                    Please Login with IOS Credential
                </Grid2>
                <Grid2 md={6} sm={12}>
                    <TextField 
                        id="userName" 
                        label="User Name" 
                        size="small" 
                        variant="standard" 
                        autoComplete="off" 
                        required 
                        onChange={(e) => handleChange(e)}
                    />
                    <br /><br />
                    <TextField 
                        id="password" 
                        label="Password" 
                        size="small" 
                        variant="standard" 
                        autoComplete="off" 
                        type="password" 
                        required
                        onChange={e => handleChange(e)}
                    />
                    <br /><br />
                    <Button variant="outlined" onClick={doLogin}>Login</Button> 
                </Grid2>
            </Grid2>
            </Container>    
        )
    }else{
    return (
        <Container>
            <br/>
            <br/>
            <h2>Tax Calculation</h2>
            <br/>

            <Grid2 container spacing={2}>
                {/*Client*/}
                <Grid2 md={6} sm={12}>
                    Welcome, {sessionStorage.getItem('token')}
                </Grid2>
                <Grid2 md={6} sm={12}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button variant="outlined" endIcon={<IoIcons.IoIosSave/>} onClick={downloadData} >
                            Download
                        </Button>
                    </div>
                </Grid2>
                <Grid2 md={6} sm={12}>
                    <Autocomplete
                        label="select-client"
                        id="id-select-client"
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="Client" />}
                        options={client}
                        getOptionLabel={(option => ('0000' + option.client_id).substr(-4) + ' | ' + option.client_name)}
                        onInputChange={(e, value) => handleChangeClients('client', value)}
                        value={state && state['client'] ? state['client'] : undefined}
                    />
                </Grid2>
                <Grid2 md={6} sm={12}>
                    <Autocomplete
                        label="select-pks"
                        id="id-select-pks"
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="PKS" />}
                        options={pks}
                        getOptionLabel={(option => ('0000' + option.pks_id).substr(-4) + ' | ' + option.pks_alias)}
                        // getOptionKey={(option => option.pks_id)}
                        onInputChange={(e, value) => handleChange('pks', value)}
                        value={state && state['pks'] ? state['pks'] : undefined}
                    />
                </Grid2>
            </Grid2>
        </Container>
    )}
}

export default Tax